import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

import gql from "graphql-tag";
export var CREATE_LOGISTICS = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation($input: LogisticsInput) {\n    createLogistics(input: $input) {\n      isSuccess\n      message\n      is_error\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var UPDATE_LOGISTICS = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation($input: LogisticsInput) {\n    updateLogistics(input: $input) {\n      isSuccess\n      message\n      is_error\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var DELETE_LOGISTICS = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation($id: Int) {\n    deleteLogistics(id: $id) {\n      isSuccess\n      message\n      is_error\n    }\n  }\n"])));
export var UPDATE_NO_LOGISTICS = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation($id: Int, $no: Int) {\n    updateNoLogistics(id: $id, no: $no) {\n      isSuccess\n      message\n      is_error\n    }\n  }\n"])));
export var GET_LOGISTICS_LIST = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query($page: Int!, $pageSize: Int!, $search: String, $sorter: Sorter) {\n    systemLogisticsList(\n      page: $page\n      pageSize: $pageSize\n      sorter: $sorter\n      search: $search\n    ) {\n      recordsData {\n        recordsFiltered\n      }\n      records {\n        _id\n        no\n        logisticsId\n        logisticsNo\n        logisticsName\n        logisticsServicePrice\n        image\n        logisticsPriceList {\n          min\n          max\n          price\n        }\n        country\n        type\n        is_address\n        default\n        created_at\n      }\n    }\n  }\n"])));
export var GET_LOGISTICS_LIST_SELECT = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query {\n    logisticsList {\n      records {\n        logisticsId\n        logisticsName\n        logisticsServicePrice\n        is_other\n        is_address\n        logisticsPriceList {\n          _id\n          max\n          min\n          price\n        }\n        type\n      }\n    }\n  }\n"])));
export var GET_LOGISTICS = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query($id: Int) {\n    logistics(id: $id) {\n      _id\n      no\n      logisticsId\n      logisticsNo\n      logisticsName\n      logisticsServicePrice\n      image\n      logisticsPriceList {\n        _id\n        min\n        max\n        price\n      }\n      country\n      is_cod\n      is_address\n      default\n    }\n  }\n"])));
export var GET_SELECT_LOGISTICS_LIST = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  query($promotion_id: Int) {\n    logisticsList(promotion_id: $promotion_id) {\n      records {\n        _id\n        no\n        logisticsId\n        logisticsName\n        logisticsServicePrice\n        logisticsPriceList {\n          min\n          max\n          price\n        }\n        type\n        is_address\n        created_at\n      }\n    }\n  }\n"])));